import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const HeroSection = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('https://i.postimg.cc/Y08CkTrJ/giphy.webp');
    background-size: cover;
    background-position: center;
    color: #ffffff;
    position: relative;
    overflow: hidden;
    z-index: 0;
`;

const HeroText = styled(motion.h1)`
    font-size: 48px;
    text-align: center;
    margin: 20px 0;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
`;

const HeroSubText = styled(motion.p)`
    font-size: 24px;
    margin-bottom: 40px;
    color: #ff5722;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 30px;
    flex-wrap: wrap;
`;

const HeroButton = styled(motion.a)`
    padding: 18px 36px;
    background: linear-gradient(45deg, #ff5722, #e64a19);
    color: #ffffff;
    border-radius: 50px;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    transition: all 0.4s ease-in-out;
    position: relative;
    display: inline-block;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    
    &:hover {
        background: linear-gradient(45deg, #e64a19, #ff5722);
        transform: translateY(-4px) scale(1.1);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    }

    &:active {
        transform: translateY(2px) scale(1.05);
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 10px 2px rgba(255, 87, 34, 0.7);
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 87, 34, 0.2);
        border-radius: 50px;
        transform: scale(0);
        transition: transform 0.3s ease-in-out;
        z-index: -1;
    }

    &:hover::after {
        transform: scale(1);
    }
`;

const AnimatedShape = styled(motion.div)`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    height: 400px;
    background: rgba(255, 87, 34, 0.2);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    filter: blur(50px);
    z-index: 0;
`;

const MainPage = () => {
    return (
        <HeroSection>
            <AnimatedShape
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
            />
            <HeroText
                initial={{ y: -100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                Welcome to Heloki Hosting
            </HeroText>
            <HeroSubText
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
            >
                Reliable, Fast, and Affordable Hosting Solutions
            </HeroSubText>
            <ButtonContainer>
                <HeroButton
                    href="https://discord.gg/heloki"
                    target="_blank"
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                >
                    Discord
                </HeroButton>
                <HeroButton
                    href="https://www.trustpilot.com/review/heloki.net"
                    target="_blank"
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.6 }}
                >
                    Trustpilot
                </HeroButton>
                <HeroButton
                    href="https://vps.heloki.net"
                    target="_blank"
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.8 }}
                >
                    VPS
                </HeroButton>
                <HeroButton
                    href="https://status.heloki.net"
                    target="_blank"
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.5, delay: 1 }}
                >
                    Status
                </HeroButton>
            </ButtonContainer>
        </HeroSection>
    );
};

export default MainPage;
