import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

// Styled Components for Testimonials Section
const TestimonialsSection = styled.section`
    padding: 60px 20px;
    text-align: center;
    background: linear-gradient(90deg, #1f1f1f, #121212);
    color: #ffffff;
`;

const TestimonialsTitle = styled.h2`
    font-size: 36px;
    margin-bottom: 40px;
    font-weight: 600;
    text-transform: uppercase;
`;

const TestimonialsContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 40px; /* Increased gap for better space between images */
    flex-wrap: wrap;
    margin-bottom: 40px;
`;

const TestimonialItem = styled(motion.div)`
    padding: 10px;
    z-index: 1;
    max-width: 600px; /* Increased width for more space for the images */
    width: 100%;
`;

const TestimonialImage = styled.img`
    width: 100%;
    height: auto;
    border-radius: 12px;
    object-fit: cover; /* Ensure the image covers the space without distortion */
    max-height: 450px; /* Increased max height for larger images */
`;

const ButtonContainer = styled.div`
    margin-top: 20px;
`;

const TrustpilotButton = styled.a`
    display: inline-flex;
    align-items: center;
    padding: 15px 30px;
    background-color: #ff5722;
    color: #fff;
    font-size: 16px;
    border-radius: 50px;
    text-decoration: none;
    transition: background 0.3s ease;

    &:hover {
        background-color: #e64a19;
    }

    img {
        margin-right: 10px;
        width: 20px; /* Size of the icon */
        height: auto;
    }
`;

// Use the Trustpilot image
const TrustpilotIcon = () => (
    <img
        src="https://i.postimg.cc/nrxGXcLY/icons8-trustpilot-48.png"
        alt="Trustpilot"
    />
);

const Testimonials = () => {
    const testimonials = [
        "https://i.postimg.cc/NjdJZT0h/image-2024-11-17-094733195.png",
        "https://i.postimg.cc/qMd5K4FF/image-2024-11-17-094556588.png",
    ];

    return (
        <TestimonialsSection>
            <TestimonialsTitle>What Our Users Say</TestimonialsTitle>
            <TestimonialsContainer>
                {testimonials.map((imageUrl, index) => (
                    <TestimonialItem
                        key={index}
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5, delay: index * 0.2 }}
                    >
                        <TestimonialImage src={imageUrl} alt={`Testimonial ${index + 1}`} />
                    </TestimonialItem>
                ))}
            </TestimonialsContainer>
            <ButtonContainer>
                <TrustpilotButton href="https://www.trustpilot.com/review/heloki.net" target="_blank">
                    <TrustpilotIcon />
                    Trustpilot
                </TrustpilotButton>
            </ButtonContainer>
        </TestimonialsSection>
    );
};

export default Testimonials;
