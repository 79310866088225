import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaDiscord } from 'react-icons/fa';
import { SiTrustpilot } from 'react-icons/si';

// Animation for subtle text glow
const textGlow = keyframes`
    0% { text-shadow: 0 0 5px #FFFFFF, 0 0 10px #FFFFFF; }
    50% { text-shadow: 0 0 20px #FFFFFF, 0 0 30px #FFFFFF; }
    100% { text-shadow: 0 0 5px #FFFFFF, 0 0 10px #FFFFFF; }
`;

// Animation for icon hover effect
const iconHover = keyframes`
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.2); }
`;

// Dark Footer styling with Deep Blue and Violet Purple accents
const FooterSection = styled.footer`
    padding: 40px 20px;
    text-align: center;
    background: #1f1f1f; /* Dark background */
    color: #F3F3F3; /* Cool Grey text */
    position: relative;
    overflow: hidden;
`;

// Social media icon styling with hover effects
const SocialIcons = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;

    svg {
        color: #F3F3F3; /* Cool Grey */
        font-size: 24px;
        transition: transform 0.3s;
        &:hover {
            animation: ${iconHover} 0.6s ease-in-out infinite;
            color: #7B5FC9; /* Violet Purple hover effect */
        }
    }
`;

// Glowing copyright text with dark theme
const CopyrightText = styled.p`
    font-size: 18px;
    font-weight: 500;
    animation: ${textGlow} 2s ease-in-out infinite;
    color: #F3F3F3; /* Cool Grey */
`;

const Footer = () => {
    return (
        <FooterSection>
            <CopyrightText>&copy; 2024 Heloki Hosting. All rights reserved.</CopyrightText>
            <SocialIcons>
                <a href="https://discord.com" target="_blank" rel="noopener noreferrer">
                    <FaDiscord />
                </a>
                <a href="https://trustpilot.com" target="_blank" rel="noopener noreferrer">
                    <SiTrustpilot />
                </a>
            </SocialIcons>
        </FooterSection>
    );
};

export default Footer;
