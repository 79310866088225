import React from 'react';
import Header from './components/Header';
import MainPage from './components/MainPage';
import Features from './components/Features';
import Pricing from './components/Pricing';
import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import Footer from './components/Footer';

const App = () => {
    return (
        <>
            <Header />
            <MainPage />
            <Features />
            <Pricing />
            <Testimonials />
            <Contact />
            <Footer />
        </>
    );
};

export default App;
