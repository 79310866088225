import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { motion } from 'framer-motion';

// Global Styles (including animations)
const GlobalStyle = createGlobalStyle`
    @keyframes fadeIn {
        0% {
            opacity: 0;
            transform: translateY(-20px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
    
    body {
        margin: 0;
        font-family: 'Roboto', sans-serif;
        background: #121212;
        color: #ffffff;
    }
`;

// Container for the header
const HeaderContainer = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: rgba(31, 31, 31, 0.9);
    color: #ffffff;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    transition: background 0.3s ease, box-shadow 0.3s ease;
    backdrop-filter: blur(12px); /* Increased blur effect */
    transform: translateY(0);
    animation: fadeIn 1s ease-out; /* Fade-in animation when the page loads */
`;

// Logo and profile image container
const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

// Profile image with hover effect (Removed circle and border)
const ProfileImage = styled(motion.img)`
    width: 60px;
    height: 60px;
    border-radius: 8px; /* Removed the circle, but still giving some rounded corners */
    transition: transform 0.3s, box-shadow 0.3s;
    
    &:hover {
        transform: scale(1.2); /* Slight zoom effect on hover */
        box-shadow: 0 0 15px rgba(255, 87, 34, 0.5); /* Glowing effect on hover */
    }
`;

// Logo text style with custom font
const Logo = styled(motion.h1)`
    font-size: 28px;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    color: #ff5722;
    transition: color 0.3s ease;

    &:hover {
        color: #ffffff;
    }
`;

// Navigation menu style
const Nav = styled.nav`
    display: flex;
    gap: 20px;
    align-items: center;
`;

// Navigation link style with hover effect
const NavLink = styled(motion.a)`
    font-size: 16px;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;
    transition: color 0.3s ease, transform 0.3s ease;
    
    &:hover {
        color: #ff5722;
        transform: translateY(-5px); /* Move links up slightly on hover */
    }
    
    &:active {
        transform: translateY(1px); /* Slight down movement when clicked */
    }
`;

const Header = () => {
    return (
        <>
            <GlobalStyle />
            <HeaderContainer>
                <LogoContainer>
                    <ProfileImage
                        src="https://i.postimg.cc/fT5Dm1dZ/pfp-removebg-preview-2.png"
                        alt="Profile"
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{ duration: 0.5 }}
                    />
                    <Logo
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1, delay: 0.2 }}
                    >
                        Heloki Hosting
                    </Logo>
                </LogoContainer>
                <Nav>
                    {['Features', 'Pricing', 'Testimonials', 'Contact'].map((item, index) => (
                        <NavLink
                            key={index}
                            href={`#${item.toLowerCase()}`}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.95 }}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.3, delay: index * 0.1 }}
                        >
                            {item}
                        </NavLink>
                    ))}
                </Nav>
            </HeaderContainer>
        </>
    );
};

export default Header;
