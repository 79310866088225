import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

// Importing Google Font
import { createGlobalStyle } from 'styled-components';

// Global Styles for the font
const GlobalStyle = createGlobalStyle`
    body {
        font-family: 'Poppins', sans-serif;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        background-color: #121212;
        color: #fff;
    }
`;

const PricingSection = styled.section`
    padding: 80px 20px;
    text-align: center;
    background: linear-gradient(90deg, #121212, #1f1f1f);
    color: #ffffff;
`;

const PricingTitle = styled.h2`
    font-size: 48px;
    margin-bottom: 60px;
    font-weight: 600;
    letter-spacing: 1.5px;
    text-transform: uppercase;
`;

const ColumnContainer = styled.div`
    display: flex;
    justify-content: space-around;
    gap: 30px;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
`;

const PlanCard = styled(motion.div)`
    background: rgba(255, 87, 34, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    margin: 20px;
    padding: 40px;
    width: 320px;
    transition: transform 0.3s, box-shadow 0.3s, border 0.3s;
    text-align: left;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 4px 30px rgba(255, 87, 34, 0.5);
        border: 1px solid #ff5722;
    }

    h3 {
        font-size: 28px;
        margin-bottom: 20px;
        font-weight: 600;
        color: #ff5722;
    }

    h4 {
        font-size: 22px;
        color: #ffffff;
        margin-bottom: 20px;
    }

    ul {
        list-style: none;
        padding: 0;
        margin-bottom: 20px;
    }

    button {
        margin-top: 20px;
        padding: 15px 25px;
        background-color: #ff5722;
        color: #fff;
        border: none;
        border-radius: 50px;
        font-size: 16px;
        cursor: pointer;
        transition: background 0.3s ease, transform 0.2s ease;

        &:hover {
            background-color: #e64a19;
            transform: scale(1.05);
        }

        &:focus {
            outline: none;
        }
    }
`;

const FeatureList = styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;

    li {
        margin: 10px 0;
        font-size: 16px;
        line-height: 1.5;
    }
`;

const Pricing = () => {
    return (
        <>
            <GlobalStyle />
            <PricingSection>
                <PricingTitle>Choose Your Plan</PricingTitle>

                <ColumnContainer>
                    {/* Free Plan */}
                    <PlanCard
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5, delay: 0 }}
                    >
                        <h3>Free</h3>
                        <h4>Free</h4>
                        <FeatureList>
                            <li>95% Uptime</li>
                            <li>2 GB RAM</li>
                            <li>4 GB Storage</li>
                            <li>1 Subdomain Support</li>
                        </FeatureList>
                        <button onClick={() => window.open('https://panel.heloki.net', '_blank')}>
                            Get Started
                        </button>
                    </PlanCard>

                    {/* Paid Plan */}
                    <PlanCard
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                    >
                        <h3>Paid (2$/month)</h3>
                        <h4>$2 / month</h4>
                        <FeatureList>
                            <li>Exclusive Support</li>
                            <li>99.9% Uptime</li>
                            <li>Domain Linking Features</li>
                            <li>1 GB RAM</li>
                            <li>3 GB Storage</li>
                            <li>Ultra Fast Hosts (Discord bot ping ~20ms)</li>
                        </FeatureList>
                        <p>
                            <em>(Paid servers can only be bought through Discord for now)</em>
                        </p>
                    </PlanCard>

                    {/* VPS Hosting Message */}
                    <PlanCard
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                    >
                        <h3>VPS Hosting</h3>
                        <p>These plans are exclusively for hosting Discord bots. If you want a separate VPS for yourself, please visit:</p>
                        <a href="https://vps.heloki.net" target="_blank" rel="noopener noreferrer">
                            <button>Visit VPS Plans</button>
                        </a>
                    </PlanCard>
                </ColumnContainer>
            </PricingSection>
        </>
    );
};

export default Pricing;
