import React from 'react';
import styled from 'styled-components';

const FeaturesSection = styled.section`
    padding: 60px 20px;
    text-align: center;
    background: linear-gradient(90deg, #121212, #1f1f1f); /* Same gradient as the main background */
    color: #ffffff;
`;

const FeatureItem = styled.div`
    margin: 20px 0;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 12px;
`;

const Features = () => {
    const features = [
        "⚡ Quick Support",
        "💰 Cost-Friendly Premium Plans",
        "🌐 24/7 Availability",
        "⚡ More cool features for the Free and Paid users"
    ];

    return (
        <FeaturesSection id="features">
            <h2>Features</h2>
            {features.map((feature, index) => (
                <FeatureItem key={index}>{feature}</FeatureItem>
            ))}
        </FeaturesSection>
    );
};

export default Features;
